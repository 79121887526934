import * as React from 'react';
import {useLocation} from "react-router-dom";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import {computeUrl} from "./util";

function LinkTab(props) {
    return (
        <Tab
            component="a"
            {...props}
        />
    );
}

export function Navigation() {
    const location = useLocation();

    const linkLandingPage = computeUrl('home.', 'localhost:3000');
    const linkDesign = computeUrl('design.', 'localhost:8889');
    const linkWork = computeUrl('', 'localhost:8090');
    const linkControl = computeUrl('control.', 'localhost:9988');
    const linkEmails = computeUrl('roundcube.', 'localhost:8093');

    const links = ['/', '/tenants'];
    let selected = links.indexOf(location.pathname);
    if (location.pathname.startsWith('/apps')) {
        selected = 0;
    }

    return <AppBar position="static">
        <Tabs
            value={selected}>
            <LinkTab label="Apps" href="/"/>
            <LinkTab label="Demo Environments" href="/tenants"/>
            <LinkTab label="Landing Page" target="_blank" className="navigation-external" href={linkLandingPage}/>
            <LinkTab label="Design" target="_blank" className="navigation-external" href={linkDesign}/>
            <LinkTab label="Work" target="_blank" className="navigation-external" href={linkWork}/>
            <LinkTab label="Control" target="_blank" className="navigation-external" href={linkControl}/>
            <LinkTab label="Emails" target="_blank" className="navigation-external" href={linkEmails}/>
        </Tabs>
    </AppBar>;
}
